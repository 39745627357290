import request from '@/api/request.js'


// 级别
export function getLevel (data) {
    return request({
      url: '/api/level/page',
      method: 'POST',
      data
    })
  }

// 添加级别
export function addOrUpdateLevel (data) {
  return request({
    url: '/api/level/addOrUpdate',
    method: 'POST',
    data
  })
}
 